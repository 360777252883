import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, colors } from '../../constants';
import squaredLogo from '../../images/squared-logo.png';
import { Container } from '../Container';
import { Hamburger } from '../Hamburger';

const Logo = styled.img`
    height: 100%;
    object-fit: contain;
`;

interface IProps {
    className?: string;
    onClick: () => void;
    showMenu: boolean;
}

const BaseNavBar = ({ className, onClick, showMenu }: IProps) => (
    <div className={className}>
        <Container>
            <Link to="/">
                <Logo src={squaredLogo} alt="Logo Squared Agency" />
            </Link>
            <Hamburger onClick={onClick} active={showMenu} />
        </Container>
    </div>
);

export const NavBar = styled(BaseNavBar)`
    height: 68px;
    border-bottom: 1px solid ${colors.greyLight};
    position: relative;

    ${Container} {
        padding: 12px 24px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media (${breakpoints.smallMin}) {
        height: 96px;
    }
`;
