export const Globe = () => (
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 234 241"
        enableBackground="new 0 0 234 241"
    >
        <g>
            <g>
                <path
                    fill="#FFFFFF"
                    d="M0,111C0,74,0,37,0,0c78,0,156,0,234,0c0,80.333,0,160.666,0,241c-78,0-156,0-234,0c0-37,0-74,0-111
				c0.978,5.188,2.158,10.351,2.866,15.576c0.498,3.676,2.012,4.907,5.812,4.849c14.649-0.229,29.303-0.084,43.955-0.105
				c1.568-0.002,3.137-0.182,4.125-0.243c0-20.085,0-39.482,0-59.024c-0.224-0.065-0.687-0.315-1.151-0.316
				C39.457,90.7,23.305,90.61,7.158,90.816c-1.336,0.017-3.482,1.847-3.823,3.196C1.924,99.599,1.063,105.325,0,111z
				M159.225,150.044c2.215-19.809,2.245-39.324-0.013-59.09c-28.985,0-57.562,0-86.549,0c-2.082,19.809-2.235,39.329,0.082,59.09
				C101.712,150.044,130.291,150.044,159.225,150.044z M74.696,75.41c27.971,0,55.006,0,82.038,0
				c0.08-8.675-5.702-31.277-12.14-43.695c-3.702-7.139-8.176-14.209-13.674-20.01c-9.122-9.625-20.314-9.231-30.011-0.11
				c-2.406,2.263-4.652,4.803-6.497,7.536C82.995,36.041,78.219,55.262,74.696,75.41z M74.443,165.521
				c1.618,7.261,2.88,13.929,4.607,20.475c3.493,13.235,8.073,26.058,16.3,37.218c12.74,17.28,28.668,17.542,40.993,0.08
				c4.921-6.972,8.957-14.864,11.856-22.895c4.023-11.145,6.597-22.812,9.965-34.878C129.623,165.521,102.564,165.521,74.443,165.521
				z M175.236,150.323c16.587,0,33.235,0.091,49.877-0.177c1.293-0.021,3.314-2.366,3.701-3.947
				c4.182-17.114,4.01-34.273,0.219-51.474c-0.733-3.328-2.23-4.161-5.418-4.123c-13.164,0.156-26.331,0.065-39.496,0.064
				c-3.108,0-6.216,0-8.883,0C175.236,110.796,175.236,130.209,175.236,150.323z M223.08,75.739
				c-15.217-33.122-39.115-54.65-73.302-65.985c3.023,5.65,5.797,10.381,8.148,15.314c7.067,14.83,10.922,30.636,14.011,46.682
				c0.284,1.474,2.012,3.801,3.101,3.818C190.661,75.818,206.289,75.739,223.08,75.739z M222.469,165.261
				c-16.092,0-31.712-0.08-47.328,0.169c-1.107,0.018-2.753,2.284-3.171,3.778c-3.534,12.628-6.297,25.5-10.406,37.93
				c-2.693,8.147-7.232,15.684-10.943,23.493C180.123,223.301,213.984,192.783,222.469,165.261z M9.548,75.722
				c16.256,0,32.025,0.067,47.791-0.164c0.951-0.014,2.452-2.285,2.712-3.688c3.041-16.396,7.079-32.484,14.311-47.599
				c2.233-4.667,4.896-9.128,7.507-13.95C52.798,16.894,18.222,48.005,9.548,75.722z M8.896,165.257
				c15.225,33.058,39.102,54.593,73.285,65.984c-3.005-5.638-5.755-10.353-8.091-15.265c-7.124-14.98-11.037-30.932-14.112-47.146
				c-0.258-1.36-2.238-3.402-3.443-3.42C41.072,165.178,25.605,165.257,8.896,165.257z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0,111c1.063-5.675,1.924-11.401,3.335-16.988
				c0.341-1.349,2.487-3.179,3.823-3.196c16.147-0.206,32.299-0.116,48.449-0.081c0.464,0.001,0.927,0.251,1.151,0.316
				c0,19.542,0,38.939,0,59.024c-0.989,0.062-2.557,0.241-4.125,0.243c-14.652,0.021-29.306-0.123-43.955,0.105
				c-3.8,0.059-5.314-1.173-5.812-4.849C2.158,140.351,0.978,135.188,0,130C0,123.666,0,117.333,0,111z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M159.225,150.044c-28.934,0-57.512,0-86.48,0
				c-2.316-19.762-2.163-39.281-0.082-59.09c28.987,0,57.564,0,86.549,0C161.469,110.72,161.44,130.235,159.225,150.044z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.696,75.41c3.523-20.147,8.299-39.369,19.717-56.28
				c1.845-2.732,4.091-5.273,6.497-7.536c9.696-9.121,20.889-9.515,30.011,0.11c5.498,5.801,9.972,12.871,13.674,20.01
				c6.438,12.418,12.219,35.021,12.14,43.695C129.702,75.41,102.667,75.41,74.696,75.41z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M74.443,165.521c28.121,0,55.18,0,83.721,0
				c-3.368,12.065-5.941,23.733-9.965,34.878c-2.899,8.03-6.935,15.923-11.856,22.895c-12.324,17.462-28.253,17.2-40.993-0.08
				c-8.228-11.16-12.808-23.982-16.3-37.218C77.324,179.45,76.061,172.782,74.443,165.521z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M175.236,150.323c0-20.114,0-39.527,0-59.656c2.667,0,5.775,0,8.883,0
				c13.165,0,26.332,0.092,39.496-0.064c3.188-0.038,4.685,0.795,5.418,4.123c3.791,17.2,3.963,34.359-0.219,51.474
				c-0.387,1.581-2.408,3.927-3.701,3.947C208.472,150.414,191.824,150.323,175.236,150.323z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M223.08,75.739c-16.791,0-32.419,0.079-48.042-0.17
				c-1.088-0.018-2.817-2.344-3.101-3.818c-3.088-16.046-6.944-31.853-14.011-46.682c-2.351-4.933-5.125-9.664-8.148-15.314
				C183.965,21.089,207.863,42.617,223.08,75.739z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M222.469,165.261c-8.484,27.522-42.346,58.04-71.85,65.37
				c3.711-7.81,8.251-15.346,10.944-23.493c4.109-12.43,6.873-25.302,10.406-37.93c0.418-1.494,2.064-3.761,3.171-3.778
				C190.756,165.181,206.377,165.261,222.469,165.261z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.548,75.722c8.674-27.716,43.25-58.828,72.32-65.401
				c-2.611,4.822-5.274,9.283-7.507,13.95c-7.231,15.114-11.27,31.203-14.311,47.599c-0.26,1.403-1.761,3.675-2.712,3.688
				C41.574,75.789,25.804,75.722,9.548,75.722z"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.896,165.257c16.71,0,32.177-0.079,47.639,0.153
				c1.206,0.018,3.186,2.06,3.443,3.42c3.076,16.215,6.988,32.166,14.112,47.146c2.336,4.912,5.086,9.627,8.091,15.265
				C47.997,219.85,24.12,198.314,8.896,165.257z"
                />
            </g>
        </g>
    </svg>
);
