import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { FilterOverlay } from '../FilterOverlay';
import { SoundBars } from '../SoundBars';
import { Skeleton } from '../Skeleton';
import { breakpoints, colors, maxAppWidth, transitions } from '../../constants';
import { useViewport } from '../../utils';
import { IArtist } from '../../types';

const ArtistLink = styled(Link)``;
const StyledImage = styled.img``;
const ArtistName = styled.h2``;

const SkeletonTitle = styled(Skeleton)`
    height: 40px;
    margin: 12px 24px;
`;

interface IProps {
    className?: string;
    artists: IArtist[] | undefined;
    loading: boolean;
}

const BaseArtistsList = ({ artists, className, loading }: IProps) => {
    const { width } = useViewport();

    // Temporary workaround for retaining squared aspect ratios (accounting for 48px total padding)
    const imageStyles = {
        height:
            width < breakpoints.small
                ? width - 48
                : width < breakpoints.medium
                ? (width - 48) / 2
                : width < breakpoints.large
                ? (width - 48) / 3
                : (maxAppWidth - 48) / 4,
    };

    const loadingView = (
        <>
            {width > breakpoints.medium && (
                <SkeletonTitle style={{ width: '180px' }} />
            )}
            <SkeletonTitle />
            <ul className={className}>
                {[...Array(16)].map((el, i) => (
                    <Skeleton key={i} style={imageStyles} />
                ))}
            </ul>
        </>
    );

    return loading || !artists ? (
        loadingView
    ) : (
        <ul className={className}>
            {artists.map((artist) => (
                <ArtistLink key={artist.id} to={`/artist/${artist.slug}`}>
                    <ArtistName>{artist.title}</ArtistName>
                    {width > breakpoints.large && (
                        <>
                            <SoundBars />
                            <FilterOverlay />
                        </>
                    )}
                    <StyledImage
                        style={imageStyles}
                        src={artist.artistDetails.artistImage.sourceUrl}
                        srcSet={artist.artistDetails.artistImage.srcSet}
                    />
                </ArtistLink>
            ))}
        </ul>
    );
};

export const ArtistsList = styled(BaseArtistsList)`
    display: grid;
    gap: 8px;
    padding: 0 24px;
    margin-bottom: 48px;

    @media (${breakpoints.smallMin}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (${breakpoints.mediumMin}) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (${breakpoints.largeMin}) {
        grid-template-columns: repeat(4, 1fr);
    }

    ${StyledImage} {
        width: 100%;
        object-fit: cover;
    }

    ${ArtistName} {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0, -50%);
        text-align: center;
        line-height: 1.1;
        color: ${colors.white};
        z-index: 3;
        transition: opacity ${transitions.linear};

        @media (${breakpoints.largeMin}) {
            opacity: 0;
        }
    }

    ${SoundBars}, ${FilterOverlay} {
        position: absolute;
        opacity: 0;
        transition: opacity ${transitions.linear};
    }

    ${SoundBars} {
        z-index: 1;
        bottom: 0;
    }

    ${FilterOverlay} {
        z-index: 2;
    }

    ${ArtistLink} {
        position: relative;
        width: 100%;

        @media (${breakpoints.largeMin}) {
            :hover {
                ${ArtistName}, ${SoundBars} {
                    opacity: 1;
                }

                ${FilterOverlay} {
                    opacity: 0.8;
                }
            }
        }
    }
`;
