import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { Line } from '../Line';
import { FETCH_CONTACT_PERSONS } from '../../graphql';
import { IContactPerson } from '../../types';

const ContactContainer = styled.div`
    text-align: center;
    margin: 48px auto;
    line-height: 1.5;

    h2 {
        margin-bottom: 12px;
    }
`;

interface IProps {
    className?: string;
}

const BaseContact = ({ className }: IProps) => {
    const { data, error, loading } = useQuery(FETCH_CONTACT_PERSONS);

    const contacts = data?.contacts.nodes;

    if (loading || !contacts) return null;

    return (
        <div className={className}>
            {error && <p>Something went wrong</p>}
            {contacts &&
                !loading &&
                contacts.map((contact: IContactPerson, index: number) => (
                    <ContactContainer key={contact.id}>
                        <h2>{contact.title}</h2>
                        <a
                            href={`mailto:${contact.contactPersonDetails.email}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {contact.contactPersonDetails.email}
                        </a>
                        <p>{contact.contactPersonDetails.phone}</p>
                        {index !== contacts.length - 1 && <Line />}
                    </ContactContainer>
                ))}
        </div>
    );
};

export const Contact = styled(BaseContact)``;
