import styled from 'styled-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FETCH_ARTIST } from '../../graphql';
import { breakpoints } from '../../constants';
import { useViewport } from '../../utils';
import { Line } from '../Line';
import { IArtistDetail, IContactPerson, Slug } from '../../types';
import { DescriptionSection } from '../DescriptionSection';
import { VideoSection } from '../VideoSection';
import { CategoryList } from '../CategoryList';
import { ContactSection } from '../ContactSection';
import { SocialsSection } from '../SocialsSection';
import { AudioSection } from '../AudioSection';
import { Skeleton } from '../Skeleton';

const Row = styled.div``;
const ImageContainer = styled.div``;
const TitleContainer = styled.div``;
const TitleSection = styled.ul``;
const StyledImage = styled.img``;

const SkeletonTitle = styled(Skeleton)`
    height: 40px;
    margin: 12px 24px;
`;

interface IProps {
    className?: string;
    setSelectedCategory: React.Dispatch<React.SetStateAction<Slug>>;
}

interface IParams {
    slug: string;
}

const BaseArtist = ({ className, setSelectedCategory }: IProps) => {
    const { slug } = useParams<IParams>();
    const { data, error, loading } = useQuery(FETCH_ARTIST, {
        variables: { slug },
    });
    const { width } = useViewport();

    const artist: IArtistDetail = data?.artistBy;
    const artistContactPerson: IContactPerson =
        artist?.artistDetails.contact[0];

    const loadingView = (
        <>
            <Skeleton style={{ height: '380px', width: '100%' }} />
            <SkeletonTitle style={{ width: '180px', marginTop: '24px' }} />
            <SkeletonTitle />
            <SkeletonTitle style={{ height: '160px', marginBottom: '24px' }} />
            <SkeletonTitle style={{ height: '320px', marginBottom: '24px' }} />
        </>
    );

    useEffect(() => {
        if (!window) return;

        window.scrollTo(0, 0);
    }, []);

    if (error) return <p>Something went wrong</p>;
    if (!artist || loading)
        return width <= breakpoints.medium ? loadingView : null;

    const {
        artistImage,
        description,
        youtubeVideoUrl,
        facebookUrl,
        imdbUrl,
        instagramUrl,
        soundcloudUrl,
        soundcloudEmbedUrl,
        spotifyUrl,
        spotifyEmbedUrl,
        twitterUrl,
        websiteUrl,
        youtubeChannelUrl,
    } = artist.artistDetails;

    const showSocialsSection =
        facebookUrl ||
        imdbUrl ||
        instagramUrl ||
        soundcloudUrl ||
        spotifyUrl ||
        twitterUrl ||
        websiteUrl ||
        youtubeChannelUrl;

    const showAudioSection = spotifyEmbedUrl || soundcloudEmbedUrl;

    const displayedCategories = artist.categories.nodes.filter(
        (cat) => cat.details.showCategory === true
    );

    return (
        <div className={className}>
            {width <= breakpoints.medium && (
                <ImageContainer>
                    <StyledImage
                        src={artistImage.sourceUrl}
                        alt={artistImage.altText}
                    />
                </ImageContainer>
            )}
            <Row>
                <TitleSection>
                    {width > breakpoints.medium && (
                        <ImageContainer>
                            <StyledImage
                                src={artistImage.sourceUrl}
                                alt={artistImage.altText}
                            />
                        </ImageContainer>
                    )}
                    <TitleContainer>
                        <h2>{artist.title}</h2>
                        <CategoryList
                            categories={displayedCategories}
                            setSelectedCategory={setSelectedCategory}
                        />
                    </TitleContainer>
                </TitleSection>
                <ContactSection contact={artistContactPerson} />
            </Row>
            {description && (
                <>
                    <DescriptionSection description={description} />
                    <Line />
                </>
            )}
            {youtubeVideoUrl && (
                <>
                    <VideoSection videoUrl={youtubeVideoUrl} />
                    <Line />
                </>
            )}
            {showAudioSection && (
                <>
                    <AudioSection
                        spotifyUrl={spotifyEmbedUrl}
                        soundcloudUrl={soundcloudEmbedUrl}
                    />
                    <Line />
                </>
            )}
            {showSocialsSection && (
                <SocialsSection
                    websiteUrl={websiteUrl}
                    facebookUrl={facebookUrl}
                    instagramUrl={instagramUrl}
                    twitterUrl={twitterUrl}
                    youtubeChannelUrl={youtubeChannelUrl}
                    spotifyUrl={spotifyUrl}
                    soundcloudUrl={soundcloudUrl}
                    imdbUrl={imdbUrl}
                />
            )}
        </div>
    );
};

export const Artist = styled(BaseArtist)`
    ${Row} {
        padding: 0 24px;
    }

    ${Row} {
        display: flex;
        flex-direction: column;
        margin: 24px 0;

        @media (${breakpoints.mediumMin}) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    ${TitleSection} {
        display: flex;
        width: 100%;
    }

    ${TitleContainer} {
        margin-bottom: 24px;
    }

    h2 {
        font-size: 32px;

        @media (${breakpoints.mediumMin}) {
            font-size: 48px;
        }
    }

    ${StyledImage} {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 20%;
    }

    ${ImageContainer} {
        width: 100%;
        height: 380px;

        @media (${breakpoints.mediumMin}) {
            height: 120px;
            max-width: 120px;
            margin-right: 24px;
        }
    }
`;
