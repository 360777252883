import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from '../Container';
import { breakpoints, colors } from '../../constants';
import Logo from '../../images/squared-logo-blue.png';

const Row = styled.div``;
const Column = styled.div``;
const LogoContainer = styled.div``;

interface IProps {
    className?: string;
}

const BaseFooter = ({ className }: IProps) => (
    <div className={className}>
        <Container>
            <Row>
                <Column>
                    <h3>OFFICE</h3>
                    <div>Squared-Agency</div>
                    <div>Generatorstraat 18</div>
                    <div>1014 AT Amsterdam</div>
                    <div>The Netherlands</div>
                </Column>
                <Column>
                    <h3>CONTACT</h3>
                    <div>
                        <a
                            href="mailto:jb@squared-agency.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            jb@squared-agency.com
                        </a>{' '}
                        / 0031-624264456
                    </div>
                    <div>
                        <a
                            href="mailto:mylene@squared-agency.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            mylene@squared-agency.com
                        </a>{' '}
                        / 0031-625478947
                    </div>
                </Column>
                <Column>
                    <Link to="/privacy-policy/">Privacy Policy</Link>
                    <br />
                    <Link to="/contact/">Contact</Link>
                </Column>
            </Row>
            <LogoContainer>
                <Link to="/">
                    <img src={Logo} />
                </Link>
            </LogoContainer>
        </Container>
    </div>
);

export const Footer = styled(BaseFooter)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100%;
    padding: 15px 0 60px;
    color: #339ea5;
    line-height: 1.5;
    background: linear-gradient(
        rgba(92, 200, 205, 0.5) 0%,
        rgba(222, 197, 227, 0.5) 100%
    );

    ${Row} {
        display: flex;
        flex-direction: column;
        text-align: center;

        @media (${breakpoints.mediumMin}) {
            flex-direction: row;
            justify-content: space-evenly;
            text-align: left;
        }
    }

    ${Column} {
        padding: 25px 0;
        font-size: 15px;
        line-height: 1.5;

        h3 {
            font-weight: 400;
            font-size: 16px;
        }

        a:hover {
            color: ${colors.blackPrimary};
            text-decoration: none;
        }
    }

    ${LogoContainer} {
        display: flex;
        justify-content: center;
        margin-top: 35px;

        img {
            height: 50px;
            width: 50px;
        }
    }
`;
