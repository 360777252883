import styled from 'styled-components';
import { IContactPerson } from '../../types';

interface IProps {
    className?: string;
    contact: IContactPerson;
}

const BaseContactSection = ({ className, contact }: IProps) => (
    <div className={className}>
        <h3>CONTACT</h3>
        <p>{contact.title}</p>
        <a
            href={`mailto:${contact.contactPersonDetails.email}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {contact.contactPersonDetails.email}
        </a>
        <p>{contact.contactPersonDetails.phone}</p>
    </div>
);

export const ContactSection = styled(BaseContactSection)`
    line-height: 1.5;
    white-space: nowrap;

    h3 {
        font-size: 16px;
    }
`;
