import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Cross } from '../../svg';
import { breakpoints, colors, transitions } from '../../constants';
import { Container } from '../Container';

const CloseButton = styled.div``;

interface IProps {
    className?: string;
    onClick: () => void;
}

const BaseOverlayMenu = ({ className, onClick }: IProps) => (
    <div className={className}>
        <Container>
            <CloseButton onClick={onClick}>
                <Cross />
            </CloseButton>
        </Container>
        <ul>
            <li>
                <Link to="/" onClick={onClick}>
                    Artists
                </Link>
            </li>
            <li>
                <Link to="/contact/" onClick={onClick}>
                    Contact
                </Link>
            </li>
        </ul>
    </div>
);

export const OverlayMenu = styled(BaseOverlayMenu)`
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: ${colors.turqoise};
    z-index: 9;

    ${Container} {
        position: relative;
    }

    ${CloseButton} {
        position: absolute;
        right: 24px;
        top: 24px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        @media (${breakpoints.smallMin}) {
            top: 40px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    li {
        padding: 24px;
        font-size: 18px;
        font-size: 24px;
        font-weight: 700;
    }

    a {
        padding: 6px 0;
        color: ${colors.white};
        transition: border ${transitions.linearFast};
        border-bottom: 4px solid transparent;

        &:hover {
            text-decoration: none;
            border-color: white;
        }
    }
`;
