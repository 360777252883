import styled from 'styled-components';
import VideoPlaceholder from '../../images/video-placeholder.jpeg';
import { getYouTubeEmbedUrl } from '../../utils';

interface IProps {
    className?: string;
    videoUrl: string;
}

const BaseVideoSection = ({ className, videoUrl }: IProps) => (
    <div className={className}>
        <iframe
            width="100%"
            height="100%"
            src={getYouTubeEmbedUrl(videoUrl)}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    </div>
);

export const VideoSection = styled(BaseVideoSection)`
    background: #000 url(${VideoPlaceholder}) no-repeat center center;
    margin: 48px auto;
    max-height: 350px;
    min-height: 280px;
    max-width: 560px;
    width: 100%;
    height: 50vw;
`;
