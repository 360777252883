import styled from 'styled-components';
import { maxAppWidth } from '../../constants';

interface IProps {
    noPadding?: boolean;
}

export const Container = styled.div<IProps>`
    width: 100%;
    max-width: ${maxAppWidth + 'px'};
    margin: 0 auto;
`;
