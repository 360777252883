import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';

import { client } from './graphql';
import { BrowserRouter as Router } from 'react-router-dom';
import { SquaredAgency } from './SquaredAgency';
import { GlobalStyle } from './GlobalStyle';

ReactDOM.render(
    <Router>
        <ApolloProvider client={client}>
            <GlobalStyle />
            <SquaredAgency />
        </ApolloProvider>
    </Router>,
    document.getElementById('root')
);
