import styled from 'styled-components';
import { createMarkup } from '../../utils';

interface IProps {
    className?: string;
    description: string;
}

const BaseDescriptionSection = ({ className, description }: IProps) => (
    <div
        className={className}
        dangerouslySetInnerHTML={createMarkup(description)}
    />
);

export const DescriptionSection = styled(BaseDescriptionSection)`
    padding: 0 24px;
    max-width: 880px;
`;
