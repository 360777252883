import styled from 'styled-components';

import { CategoryItem } from '../CategoryItem';
import { ICategory, Slug } from '../../types';
import { colors, transitions } from '../../constants';

const CategoriesContainer = styled.div``;

interface IProps {
    categories: ICategory[];
    className?: string;
    selectedCategory: string;
    onCategoryClick(slug: Slug): void;
}

const BaseCategorySelector = ({
    categories,
    className,
    selectedCategory,
    onCategoryClick,
}: IProps) => (
    <div className={className}>
        <h3>Artists</h3>
        <CategoriesContainer>
            {categories.map((category) => {
                const isActive = selectedCategory === category.slug;

                return (
                    <CategoryItem
                        isActive={isActive}
                        key={category.id}
                        name={category.name}
                        slug={category.slug}
                        onCategoryClick={onCategoryClick}
                    />
                );
            })}
        </CategoriesContainer>
    </div>
);

export const CategorySelector = styled(BaseCategorySelector)`
    h3 {
        margin-top: 24px;
        margin-left: 24px;
        color: ${colors.turqoise};
    }

    ${CategoriesContainer} {
        display: flex;
        overflow-x: auto;
        padding: 12px 8px 24px;
    }

    ${CategoryItem} {
        margin: 0 16px;
        cursor: pointer;
        line-height: 1.8;
        transition: color ${transitions.linearFast};

        :hover {
            color: ${colors.turqoise};
        }
    }
`;
