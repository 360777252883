import { useState } from 'react';
import styled from 'styled-components';

import { CategoryItem } from '../CategoryItem';
import { ICategory, Slug } from '../../types';
import { colors } from '../../constants';
import { Arrow } from '../../svg';

interface IArrowProps {
    showMenu: boolean;
}

const StyledArrow = styled(Arrow)<IArrowProps>`
    transition: transform 0.2s ease-in;
    transform: ${(props) => props.showMenu && `rotate(180deg)`};
`;

const Title = styled.div``;

interface IProps {
    categories: ICategory[];
    className?: string;
    selectedCategory: string;
    onCategoryClick(slug: Slug): void;
}

const BaseCategorySelectorMobile = ({
    categories,
    className,
    selectedCategory,
    onCategoryClick,
}: IProps) => {
    const [showMenu, setShowMenu] = useState(false);

    const currentCategory = categories.find(
        (cat) => cat.slug === selectedCategory
    );

    // Temporary workaround for animating menu height
    const fullHeight = (categories.length + 1) * 32;
    const menuHeight = showMenu ? `${fullHeight}px` : `32px`;

    return (
        <div className={className} style={{ height: menuHeight }}>
            <Title onClick={() => setShowMenu(!showMenu)}>
                {currentCategory?.name} artists
                <StyledArrow showMenu={showMenu} />
            </Title>
            {categories.map((category) => (
                <CategoryItem
                    key={category.id}
                    slug={category.slug}
                    name={category.name}
                    onCategoryClick={onCategoryClick}
                />
            ))}
        </div>
    );
};

export const CategorySelectorMobile = styled(BaseCategorySelectorMobile)`
    color: ${colors.turqoise};
    margin-bottom: 24px;
    overflow: hidden;
    transition: height 0.25s ease-in-out;

    ${Title}, ${CategoryItem} {
        height: 32px;
        padding: 6px 24px;
        font-size: 16px;
        border-bottom: 1px solid ${colors.greyLight};
        cursor: pointer;
    }

    ${Title} {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
    }

    ${StyledArrow} {
        height: 20px;
        width: 20px;
    }
`;
