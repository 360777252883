const small = 480;
const medium = 900;
const large = 1200;

export const breakpoints = {
    small,
    smallMin: `min-width: ${small}px`,
    medium,
    mediumMin: `min-width: ${medium}px`,
    large,
    largeMin: `min-width: ${large}px`,
};
