import React from 'react';
import styled from 'styled-components';

interface IProps {
    className?: string;
    href: string;
}

const BaseIconWrapper: React.FC<IProps> = ({ className, children, href }) => (
    <a
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
    >
        {children}
    </a>
);

export const IconWrapper = styled(BaseIconWrapper)``;
