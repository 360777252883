/* eslint-disable prettier/prettier */
import { gql } from '@apollo/client';

export const FETCH_APP_DATA = gql`
    query FetchAppData {
        artists(first: 100) {
            nodes {
                id
                title
                slug
                categories {
                    nodes {
                        id
                        slug
                        details {
                            showCategory
                        }
                    }
                }
                artistDetails {
                    artistImage {
                        altText
                        sourceUrl(size: MEDIUM)
                        srcSet
                    }
                }
            }
        }
        categories {
            nodes {
                id
                name
                slug
                details {
                    showCategory
                }
            }
        }
    }
`;

export const FETCH_ARTIST = gql`
    query FetchArtist($slug: String) {
        artistBy(slug: $slug) {
            slug
            title
            categories {
                nodes {
                    id
                    name
                    slug
                        details {
                            showCategory
                        }
                }
            }
            artistDetails {
                description
                youtubeVideoUrl
                artistImage {
                    altText
                    sourceUrl(size: MEDIUM_LARGE)
                }
                contact {
                    ... on Contact {
                        id
                        title
                        contactPersonDetails {
                            email
                            phone
                        }
                    }
                }
                facebookUrl
                imdbUrl
                instagramUrl
                soundcloudUrl
                soundcloudEmbedUrl
                spotifyUrl
                spotifyEmbedUrl
                twitterUrl
                websiteUrl
                youtubeChannelUrl
            }
        }
    }
`;

export const FETCH_CONTACT_PERSONS = gql`
    query FetchContactPersons {
        contacts {
            nodes {
                id
                title
                contactPersonDetails {
                    email
                    phone
                }
            }
        }
    }
`;
