import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { Home } from './components/Home';
import { Footer } from './components/Footer';
import { Contact } from './components/Contact';
import { Artist } from './components/Artist';
import { NavBar } from './components/NavBar';
import { Container } from './components/Container';
import { OverlayMenu } from './components/OverlayMenu';
import { StaticCategories, Slug } from './types';

const FillSpaceContainer = styled.div``;

interface IProps {
    className?: string;
}

const BaseSquaredAgency = ({ className }: IProps) => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Slug>(
        StaticCategories.All
    );

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <div className={className}>
            {showMenu && <OverlayMenu onClick={toggleMenu} />}
            <FillSpaceContainer>
                <NavBar onClick={toggleMenu} showMenu={showMenu} />
                <Container>
                    <Switch>
                        <Route path={`/artist/:slug`}>
                            <Artist setSelectedCategory={setSelectedCategory} />
                        </Route>
                        <Route path="/contact" component={Contact} />
                        <Route path="/">
                            <Home
                                selectedCategory={selectedCategory}
                                setSelectedCategory={setSelectedCategory}
                            />
                        </Route>
                    </Switch>
                </Container>
            </FillSpaceContainer>
            <Footer />
        </div>
    );
};

export const SquaredAgency = styled(BaseSquaredAgency)`
    height: 100%;
    display: flex;
    flex-direction: column;

    ${FillSpaceContainer} {
        flex: 1;
    }
`;
