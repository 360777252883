import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ICategory, Slug } from '../../types';

const CategoryLink = styled(Link)``;

interface IProps {
    className?: string;
    categories: ICategory[];
    setSelectedCategory: React.Dispatch<React.SetStateAction<Slug>>;
}

const BaseCategoryList = ({
    className,
    categories,
    setSelectedCategory,
}: IProps) => (
    <ul className={className}>
        {categories.length > 1 ? 'Artist categories: ' : 'Artist category: '}
        {categories.map((cat, index, array) => (
            <span key={cat.id}>
                <CategoryLink
                    to="/"
                    onClick={() => setSelectedCategory(cat.slug)}
                >
                    {cat.name}
                </CategoryLink>
                {index < array.length - 1 && ', '}
            </span>
        ))}
    </ul>
);

export const CategoryList = styled(BaseCategoryList)``;
