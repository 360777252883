import styled from 'styled-components';

interface IProps {
    className?: string;
}

const BaseCross = ({ className }: IProps) => (
    <svg
        className={className}
        viewBox="0 0 74 74"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M66.2593 1.70533C67.8214 0.143229 70.3541 0.143227 71.9162 1.70532C73.4783 3.26742 73.4783 5.80008 71.9162 7.36218L6.86236 72.416C5.30027 73.9781 2.76761 73.9781 1.20551 72.416C-0.356589 70.8539 -0.356589 68.3212 1.20551 66.7591L66.2593 1.70533Z"
            fill="white"
        />
        <path
            d="M7.48528 1.82843C5.92318 0.266337 3.39052 0.266335 1.82842 1.82843C0.266326 3.39053 0.266326 5.92319 1.82842 7.48529L66.8822 72.5391C68.4443 74.1012 70.977 74.1012 72.5391 72.5391C74.1012 70.977 74.1012 68.4444 72.5391 66.8823L7.48528 1.82843Z"
            fill="white"
        />
    </svg>
);

export const Cross = styled(BaseCross)``;
