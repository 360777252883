import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { CategorySelector } from '../CategorySelector';
import { CategorySelectorMobile } from '../CategorySelectorMobile';
import { ArtistsList } from '../ArtistsList';
import { FETCH_APP_DATA } from '../../graphql';
import { useViewport } from '../../utils';
import { breakpoints } from '../../constants';
import { IArtist, ICategory, Maybe, StaticCategories, Slug } from '../../types';

interface IProps {
    className?: string;
    selectedCategory: Slug;
    setSelectedCategory: React.Dispatch<React.SetStateAction<Slug>>;
}

const BaseHome = ({
    className,
    selectedCategory,
    setSelectedCategory,
}: IProps) => {
    const { width } = useViewport();
    const { data, error, loading } = useQuery(FETCH_APP_DATA);
    const [categories, setCategories] = useState<Maybe<ICategory[]>>([]);
    const [displayedArtists, setDisplayedArtists] = useState<Maybe<IArtist[]>>(
        []
    );

    useEffect(() => {
        if (!data) return;

        function getValidCategories(artist: IArtist) {
            const artistHasValidCategory = artist.categories.nodes.find(
                (cat) => cat.details.showCategory === true
            );

            if (!artistHasValidCategory) return;

            return artist;
        }

        const allArtists = data.artists.nodes
            .filter((artist: IArtist) => getValidCategories(artist))
            .sort((a: IArtist, b: IArtist) => a.title.localeCompare(b.title));

        const allCategories = data.categories.nodes.filter(
            (category: ICategory) =>
                category.slug !== StaticCategories.None &&
                category.details.showCategory
        );

        const defaultCategory: ICategory = {
            id: 'xRaEJs9J9gcc3&aFY',
            name: 'All',
            slug: StaticCategories.All,
            details: {
                showCategory: true,
            },
        };

        // Add default category 'All', used for displaying all artists
        allCategories.unshift(defaultCategory);

        const filteredArtists = allArtists.filter((artist: IArtist) =>
            artist.categories.nodes
                .map((cat) => cat.slug)
                .includes(selectedCategory)
        );

        !selectedCategory || selectedCategory === StaticCategories.All
            ? setDisplayedArtists(allArtists)
            : setDisplayedArtists(filteredArtists);

        setCategories(allCategories);
    }, [data, selectedCategory]);

    const showCategorySelector = categories && categories.length > 1;

    return (
        <div className={className}>
            {error && <p>Something went wrong</p>}

            {showCategorySelector &&
                (width > breakpoints.medium ? (
                    <CategorySelector
                        categories={categories}
                        onCategoryClick={setSelectedCategory}
                        selectedCategory={selectedCategory}
                    />
                ) : (
                    <CategorySelectorMobile
                        categories={categories}
                        onCategoryClick={setSelectedCategory}
                        selectedCategory={selectedCategory}
                    />
                ))}
            <ArtistsList artists={displayedArtists} loading={loading} />
        </div>
    );
};

export const Home = styled(BaseHome)``;
