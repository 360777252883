import styled from 'styled-components';

import { colors } from '../../constants';
import { IconWrapper } from '../IconWrapper';
import {
    Globe,
    Facebook,
    Instagram,
    Twitter,
    YouTube,
    Spotify,
    Soundcloud,
    Imdb,
} from '../../svg';

interface IProps {
    className?: string;
    websiteUrl?: string;
    facebookUrl?: string;
    instagramUrl?: string;
    twitterUrl?: string;
    youtubeChannelUrl?: string;
    spotifyUrl?: string;
    soundcloudUrl?: string;
    imdbUrl?: string;
}

const BaseSocialsSection = ({
    className,
    websiteUrl,
    facebookUrl,
    instagramUrl,
    twitterUrl,
    youtubeChannelUrl,
    spotifyUrl,
    soundcloudUrl,
    imdbUrl,
}: IProps) => (
    <div className={className}>
        {websiteUrl && (
            <IconWrapper href={websiteUrl}>
                <Globe />
            </IconWrapper>
        )}
        {facebookUrl && (
            <IconWrapper href={facebookUrl}>
                <Facebook />
            </IconWrapper>
        )}
        {instagramUrl && (
            <IconWrapper href={instagramUrl}>
                <Instagram />
            </IconWrapper>
        )}
        {twitterUrl && (
            <IconWrapper href={twitterUrl}>
                <Twitter />
            </IconWrapper>
        )}
        {youtubeChannelUrl && (
            <IconWrapper href={youtubeChannelUrl}>
                <YouTube />
            </IconWrapper>
        )}
        {spotifyUrl && (
            <IconWrapper href={spotifyUrl}>
                <Spotify />
            </IconWrapper>
        )}
        {soundcloudUrl && (
            <IconWrapper href={soundcloudUrl}>
                <Soundcloud />
            </IconWrapper>
        )}
        {imdbUrl && (
            <IconWrapper href={imdbUrl}>
                <Imdb />
            </IconWrapper>
        )}
    </div>
);

export const SocialsSection = styled(BaseSocialsSection)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 48px;

    ${IconWrapper} {
        margin: 20px;
    }

    svg {
        height: 32px;
        width: 32px;
        fill: ${colors.turqoise};
        transition: fill 0.05s linear;

        &:hover {
            fill: ${colors.blackPrimary};
        }
    }
`;
