import styled, { keyframes } from 'styled-components';
import { colors } from '../../constants';

const SoundBar = styled.div``;

const soundbarAnimation = keyframes`
    from {
      height: 0;
      opacity: 0.4;
    }
  
    to {
      height: 100%;
      opacity: 0.8;
    }
`;

interface IProps {
    className?: string;
}

const BaseSoundBars = ({ className }: IProps) => (
    <div className={className}>
        {[...Array(15)].map((e, i) => (
            <SoundBar key={i} />
        ))}
    </div>
);

export const SoundBars = styled(BaseSoundBars)`
    width: 100%;
    height: 30%;
    display: flex;
    align-items: flex-end;

    ${SoundBar} {
        width: calc(10% - 4px);
        margin: 0 2px;
        background-color: ${colors.white};
        animation: 0.8s ${soundbarAnimation} -4s linear infinite alternate;

        &:first-of-type {
            margin-left: 4px;
        }

        &:last-of-type {
            margin-right: 4px;
        }

        &:nth-of-type(1) {
            animation-duration: calc(0.45s + 74ms);
        }

        &:nth-of-type(2) {
            animation-duration: calc(0.45s + 33ms);
        }

        &:nth-of-type(3) {
            animation-duration: calc(0.45s + 07ms);
        }

        &:nth-of-type(4) {
            animation-duration: calc(0.45s + 58ms);
        }

        &:nth-of-type(5) {
            animation-duration: calc(0.45s);
        }

        &:nth-of-type(6) {
            animation-duration: calc(0.45s + 27ms);
        }

        &:nth-of-type(7) {
            animation-duration: calc(0.45s + 41ms);
        }

        &:nth-of-type(8) {
            animation-duration: calc(0.45s + 19ms);
        }

        &:nth-of-type(9) {
            animation-duration: calc(0.45s + 87ms);
        }

        &:nth-of-type(10) {
            animation-duration: calc(0.45s + 42ms);
        }

        &:nth-of-type(11) {
            animation-duration: calc(0.45s + 99ms);
        }

        &:nth-of-type(12) {
            animation-duration: calc(0.45s + 61ms);
        }

        &:nth-of-type(13) {
            animation-duration: calc(0.45s + 29ms);
        }

        &:nth-of-type(14) {
            animation-duration: calc(0.45s + 89ms);
        }

        &:nth-of-type(15) {
            animation-duration: calc(0.45s + 07ms);
        }
    }
`;
