import styled from 'styled-components';
import { colors } from '../../constants';

export const FilterOverlay = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        ${colors.pink} 0%,
        ${colors.turqoise} 100%
    );
`;
