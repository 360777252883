import { createGlobalStyle } from 'styled-components';
import { colors, transitions } from './constants';

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100%;
    }

    body {
        font-family: 'Lato', sans-serif;
        margin: 0;
        padding: 0;
        font-weight: 400;
        color: ${colors.blackPrimary};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    p, h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    p {
        line-height: 1.5;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    button {
        font-family: inherit;
    }

    a {
        height: 100%;
        line-height: 0;
        color: ${colors.turqoise};
        transition: color ${transitions.linearFast};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;
