import styled from 'styled-components';
import { colors } from '../../constants';

interface IProps {
    className?: string;
    isActive?: boolean;
    key?: string;
    name: string;
    slug: string;
    onCategoryClick(slug: string): void;
}

const BaseCategoryItem = ({
    className,
    name,
    slug,
    onCategoryClick,
}: IProps) => (
    <div
        className={className}
        onClick={() => {
            onCategoryClick(slug);
        }}
    >
        {name}
    </div>
);

export const CategoryItem = styled(BaseCategoryItem)`
    color: ${({ isActive }) => isActive && colors.turqoise};
    border-bottom: ${({ isActive }) =>
        isActive && `2px solid ${colors.turqoise}`};
`;
