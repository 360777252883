import styled from 'styled-components';
import { breakpoints } from '../../constants';

const AudioPlayer = styled.iframe``;

interface IProps {
    className?: string;
    spotifyUrl?: string;
    soundcloudUrl?: string;
}

const BaseAudioSection = ({ className, spotifyUrl, soundcloudUrl }: IProps) => (
    <div className={className}>
        {spotifyUrl && (
            <AudioPlayer
                src={spotifyUrl}
                width="300"
                height="380"
                frameBorder="0"
                allow="encrypted-media"
            ></AudioPlayer>
        )}
        {soundcloudUrl && (
            <AudioPlayer
                src={soundcloudUrl}
                width="300"
                height="380"
                frameBorder="0"
                allow="encrypted-media"
            ></AudioPlayer>
        )}
    </div>
);

export const AudioSection = styled(BaseAudioSection)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;

    ${AudioPlayer} {
        margin: 24px 0;

        @media (${breakpoints.mediumMin}) {
            margin: 0;
        }
    }

    @media (${breakpoints.mediumMin}) {
        flex-direction: row;
        justify-content: space-evenly;
    }
`;
