export const colors = {
    black: '#000000',
    blackPrimary: '#363636',
    greyLight: '#F5F5F5',
    greyMedium: '#EEEEEE',
    pink: '#dec5e3',
    turqoise: '#339ea5',
    turqoiseLight: '#5cc8cd',
    white: '#FFFFFF',
};
