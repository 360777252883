import styled from 'styled-components';
import { colors } from '../../constants';

const Line = styled.span`
    display: inherit;
    height: 2px;
    width: 100%;
    background: ${colors.turqoise};
    border-radius: 5px;
`;

export const TopLine = styled(Line)``;

export const MiddleLine = styled(Line)`
    margin: 4px 0;
`;

export const BottomLine = styled(Line)``;

interface IProps {
    className?: string;
    active: boolean;
    onClick(): void;
}

const BaseHamburger: React.FC<IProps> = ({ className, active, onClick }) => (
    <div className={className} onClick={onClick}>
        <TopLine />
        <MiddleLine />
        <BottomLine />
    </div>
);

export const Hamburger = styled(BaseHamburger)`
    width: 20px;

    :hover {
        cursor: pointer;
    }
`;
